import './App.css';
import BMICalculator from './components/BMI/BMICalculator';
import Footer from './components/Footer/Footer';
import Hero from './components/Hero/Hero'
import Join from './components/Join/Join';
import Plans from './components/Plans/Plans';
import Programs from './components/Programs/Programs';
import Reasons from './components/Reasons/Reasons';
import Testimonials from  './components/Testimonials/Testimonials'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  
  return (
    <div className="App">
      <Hero />
      <Programs />
      <Reasons />
      <Plans />
      <BMICalculator/>
      <Testimonials />
      <Join/>
      <Footer/>
      <ToastContainer theme="dark" position="top-center" />


    </div>
  );
}

export default App;
